import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AgbPage = () => (
    <Layout>
        <Seo title="Allgemeine Geschäftsbedingungen" />
        <div className="boxed generic-page max-w-prose">
            <h1>Allgemeine Geschäftsbedingungen</h1>
            <h3><strong>1. Vermietungsvoraussetzung</strong></h3>
            <p>Das Mindestalter für Sportboote mit Motor beträgt 18 Jahre. Vorzulegen sind: Bootsführerschein Binnen (nur für Führerscheinpflichtige Boote. DDR-Schein ist gültig), Personalausweis (oder Pass nebst Meldebescheinigung).</p>
            <h3>2. Pflichten des Vermieters</h3>
            <p>a) Der Vermieter übergibt dem Mieter zum Gebrauch ein verkehrssicheres und technisch einwandfreies Wasserfahrzeug mit Zubehör.<br /> b) Haftung: Der Vermieter und seine Mitarbeiter haften nur bei Vorsatz und grober Fahrlässigkeit. Generell wird seitens des Vermieters keine Haftung für Verletzungen u. Schäden übernommen. Eltern haften für ihre Kinder. Ausgeschlossen wird die Haftung für zeitliche Verzögerungen die dem Mieter entstehen z.B. Nichtdurchführbarkeit von Reiseplänen, insbesondere durch Defekte oder Motorschaden am Boot.<br /> c) Für liegen gelassene, vergessene u. verloren gegangene Sachen des Mieters und dessen Begleitung wird keine Haftung übernommen.</p>
            <h3>3. Rückgabe der Mietsache</h3>
            <p>a) Das Wasserfahrzeug ist spätestens 15 Minuten vor Ende der gebuchten Zeit ausschließlich vom Mieter an den Ausgangsort, in einem gereinigten ordnungsgemäßen und einem— Vermiet-Fähigen Zustand—zurück zu geben.<br /> b) Wird die Mietsache mehr als 10 Minuten später zurück gegeben, so haftet der Mieter gegenüber dem Vermieter für den Zeitraum der Überschreitung. Unbeschadet weiterer Haftung wird als Entschädigung für jede angefangene laufende Stunde eine Entschädigung in Höhe einer Stunde Mietzeit berechnet.<br /> c) Die Rückgabe kann nur während der Geschäftszeit erfolgen. Nach Geschäftsschluss werden die jeweiligen Mietstunden bis zur darauffolgenden Geschäftsöffnungszeit dem Mieter in Rechnung gestellt, mindestens jedoch eine Tagesmiete .Gibt der Mieter das Boot früher als den im Vertrag genannten Zeitraum zurück, berechtigt ihn das nicht, den Mietpreis zu mindern</p>
            <h3>4. Pflichten des Mieters</h3>
            <p>a) Der Mietpreis, eventuelle Kosten für vereinbarte Zusatzversicherungen, Kaution sowie Betriebskosten müssen vor Fahrantritt in bar entrichtet werden. Schecks, Kreditkarten werden nicht akzeptiert.<br /> b) Untersagt sind: Motorsportliche Veranstaltungen, Überschreitung der zulässigen Personenanzahl u. des Zuladungsgewichtes, gewerbliche Nutzung, Güterbeförderung und sonstige rechtswidrige Zwecken insbesondere das Befestigen an Bäumen und Ufern sowie das Ankern nach Sonnenuntergang.<br /> c) Obhutspflicht: Der Mieter hat das Wasserfahrzeug sorgsam zu behandeln. Er muss die technischen Regeln beachten, die Wassertiefen unter 1 Meter meiden, das Fahrzeug vor Sonnenuntergang nur in Häfen an Steganlagen ordnungsgemäß sichern.<br /> d) Bei Unfällen hat der Mieter unverzüglich die Wasserschutzpolizei Tel. 030-4664983360 zu rufen und bis zum Eintreffen zu warten. Ebenfalls ist der Vermieter zu benachrichtigen. Schuldanerkenntnisse dürfen vom Mieter nicht getroffen werden. Ebenfalls dürfen gegnerische Ansprüche nicht anerkannt werden.<br /> e) Der Mieter ist ferner verpflichtet einen Unfallbericht in freier Form schriftlich zu fertigen mit Skizze, dem Unfallort, den beteiligten Personen und Kennzeichen des Bootes sowie etwaige Zeugen aufzuzeichnen und dem Vermieter spätestens bei der Rückgabe des Bootes auszuhändigen.<br /> f) Schäden sind spätestens bei der Rückgabe dem Vermieter unverzüglich anzuzeigen.</p>
            <h3>5. Haftung des Mieters</h3>
            <p>a) Der Mieter haftet für alle Vertragsverletzungen, insbesondere vorsätzliches und grobes Verschulden, Alkohol und Drogen und wenn er das Boot beschädigt. Er hat das Boot in demselben Zustand zurück zu geben, wie er es bei Fahrantritt übernommen hat. Die Haftung erstreckt sich auch auf alle Folgekosten z.B. Reparatur, Sachverständigen u. Abschleppkosten, Wertminderung, Mietausfall, Selbstbeteiligung usw. Für jeden Tag an dem das Fahrzeug dem Vermieter nicht zur Verfügung steht, haftet der Mieter jeweils mit einer Tagesmiete. Im Fall der Vertragsverletzung kann der Vermieter das Mietverhältnis sofort beenden. Ansprüche des Mieters gelten damit als verwirkt.<br /> b) Diebstahl, Feuer, Explosion : Wird das Boot, sowie die mit dem Boot fest verbundene Teile entwendet oder durch Feuer u. Explosion beschädigt und der Mieter der Anzeigepflicht Punkt 4./e) erfüllt hat u. keine Vertragsverletzung vorliegt, ist der Selbstbehalt fällig.</p>
            <h3>6) Anzahlungen</h3>
            <p>Der angezahlte Betrag erlischt ersatzlos, wenn die Fahrt nicht innerhalb der ersten 90 Buchungsminuten angetreten wird.</p>
            <h3>7. Schlussbestimmung</h3>
            <p>Mündliche Nebenabreden wurden nicht getroffen und bedürfen der Schriftform. Sollten einzelne Bestimmungen unwirksam sein, so gilt dies nicht für die übrigen Mietbedingungen. (Salvatorische Klausel)<br /> <em>Es gilt deutsches Recht, Gerichtstand: Berlin</em></p>
        </div>
    </Layout>
)

export default AgbPage